<template>
  <div v-if="inpostCourierEnabled === '1'">
    <div class="row col-12">
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center">
        <div class="d-flex flex-column">
          <input type="radio"
                 :id="`courier-inpost-${index}`"
                 :name="`courier-${index}`"
                 :value="inpostCourierEnabled === '0' ? courierType.INPOST_LOCKER : courierType.INPOST_COURIER"
                 :checked="selectedCourier === courierType.INPOST_LOCKER || courier===courierType.INPOST_COURIER"
                 v-model="selectedCourier"
                 class="custom-control-input"
          >
          <label class="custom-control-label text-uppercase mr-2"
                 :for="`courier-inpost-${index}`">Przesyłka </label>
          <span class="help-text" v-if="!isEmpty(shipmentPrice)">{{ renderInpostPrice() }}</span>
        </div>
        <img class="inpost-logo" src="../../../assets/logo-inpost.png" alt="Inpost Logo"/>
      </div>
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center mt-2 mt-md-3 mt-lg-0">
        <div class="d-flex flex-column mr-2">
          <input type="radio"
                 :id="`courier-dpd-${index}`"
                 :name="`courier-${index}`"
                 value="DPD"
                 v-model="selectedCourier"
                 class="custom-control-input">
          <label class="custom-control-label text-uppercase mr-2"
                 :for="`courier-dpd-${index}`">
            Przesyłka
          </label>

          <span class="help-text" v-if="!isEmpty(shipmentPrice)">Koszt {{ shipmentPrice.DPD.toFormat() }}</span>
        </div>
        <img class="dpd-logo" src="../../../assets/logo-dpd.png" alt="DPD Logo"/>
      </div>
    </div>
    <hr v-if="selectedCourier === courierType.INPOST_COURIER">
    <div class="row col-12" v-if="selectedCourier === courierType.INPOST_COURIER">
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center">
        <div class="d-flex flex-column mr-2">
          <input type="radio"
                 :id="`return-by-parcel-locker-${index}`"
                 :name="`return-package-${index}`"
                 value="INPOST_LOCKER"
                 v-model="selectedInpostType" class="custom-control-input">
          <label class="custom-control-label text-uppercase"
                 :for="`return-by-parcel-locker-${index}`">
            Paczkomat
          </label>
          <span class="help-text" v-if="!isEmpty(shipmentPrice)">Koszt {{ shipmentPrice.INPOST_LOCKER.toFormat() }}</span>
        </div>
      </div>
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center mt-md-3 mt-lg-0">
        <div class="d-flex flex-column mt-3 mt-md-0">
          <input type="radio"
                 :id="`return-by-courier-${index}`"
                 :name="`return-package-${index}`"
                 value="INPOST_COURIER"
                 v-model="selectedInpostType"
                 class="custom-control-input">
          <label class="custom-control-label text-uppercase" :for="`return-by-courier-${index}`">
            Kurier
          </label>
          <span class="help-text" v-if="!isEmpty(shipmentPrice)">Koszt w dwie strony {{ shipmentPrice.INPOST_COURIER.toFormat() }}</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row col-12">
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center">
        <div class="d-flex flex-column">
          <input type="radio"
                 :id="`courier-inpost-locker-${index}`"
                 :name="`locker-${index}`"
                 :value="courierType.INPOST_LOCKER"
                 :checked="courier===courierType.INPOST_LOCKER"
                 v-model="selectedCourier"
                 class="custom-control-input"
          >
          <label class="custom-control-label text-uppercase mr-2"
                 :for="`courier-inpost-locker-${index}`">Paczkomat </label>
          <span class="help-text" v-if="!isEmpty(shipmentPrice)">Koszt w dwie strony {{ renderInpostPrice() }}</span>
        </div>
        <img class="inpost-logo" src="../../../assets/logo-inpost.png" alt="Inpost Logo"/>
      </div>
      <div class="custom-control custom-radio col-12 col-lg-6 d-flex align-items-center mt-2 mt-md-3 mt-lg-0">
        <div class="d-flex flex-column mr-2">
          <input type="radio"
                 :id="`courier-dpd-${index}`"
                 :name="`courier-${index}`"
                 :value="courierType.DPD"
                 v-model="selectedCourier"
                 class="custom-control-input">
          <label class="custom-control-label text-uppercase mr-2"
                 :for="`courier-dpd-${index}`">
            Przesyłka
          </label>

          <span class="help-text" v-if="!isEmpty(shipmentPrice)">Koszt w dwie strony {{ shipmentPrice.DPD.toFormat() }}</span>
        </div>
        <img class="dpd-logo" src="../../../assets/logo-dpd.png" alt="DPD Logo"/>
      </div>
    </div>
  </div>
</template>
<script>
import {courierType} from '../../config';
import {isEmpty} from 'lodash';

export default {
  props: {
    index: String,
    courier: String,
    shipmentPrice: {
      type: Object,
      default: () => {}
    },
    inpostCourierEnabled: String
  },
  data() {
    return {
      isEmpty,
      courierType,
      selectedCourier: this.getSelectedCourier(),
      selectedInpostType: this.courier === courierType.INPOST_LOCKER ? courierType.INPOST_LOCKER : courierType.INPOST_COURIER
    }
  },
  watch: {
    selectedCourier: function () {
      this.$emit('updateCourier', this.selectedCourier)
    },
    selectedInpostType: function () {
      this.$emit('updateCourier', this.selectedInpostType)
    }
  },
  methods: {
    getSelectedCourier() {
      if (this.courier === courierType.DPD) {
        return courierType.DPD
      }

      if (this.inpostCourierEnabled === '0') {
        this.$emit('updateInpostType', courierType.INPOST_LOCKER)
        return courierType.INPOST_LOCKER
      }

      return courierType.INPOST_COURIER;
    },
    renderInpostPrice() {
      const {INPOST_LOCKER, INPOST_COURIER} = this.shipmentPrice
      const courier = INPOST_COURIER.toFormat()
      const locker = INPOST_LOCKER.toFormat()
      return INPOST_LOCKER > INPOST_COURIER
          ? `${courier}`
          : `${locker}`;
    },
  }
}
</script>
