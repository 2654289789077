export const API_URL = '/api/';
export default API_URL;

export const courierType = {
    INPOST_COURIER: 'INPOST_COURIER',
    INPOST_LOCKER: 'INPOST_LOCKER',
    DPD: 'DPD'
}

export const orderStructure = {
    A: 1,
    S: 0,
    useCompanyAddress: false,
    is_medical_entity: true,
    post_code: '',
    city: '',
    street: '',
    locker: '',
    decontamination_phone: '',
    decontamination_email: '',
    decontamination_name: '',
    decontamination_period: 30,
    number_of_sporals: 1,
    next_check: '',
    courier: courierType.DPD,
    inpost_type: courierType.DPD,
    inpost_locker: {},
    savings: 0,
    shipping: 0,
    testSum: 0,
}


export const mobileScreenWidth = 768
