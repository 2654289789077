import {reduce} from 'lodash';

import {extend, localize} from 'vee-validate';
import pl from 'vee-validate/dist/locale/pl.json';
import * as rules from 'vee-validate/dist/rules';
import {UserService} from './api.service';


Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

const parseNip = (nip) => {
    return nip.replace(/[\ \-]/gi, '');
}

const nipValidator = (nip) => {
    if (typeof nip !== 'string') {
        return false
    }
    nip = parseNip(nip)

    if (nip.length > 10) {
        return false
    }

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const controlNumber = parseInt(nip.substring(9, 10));

    const sum = reduce(nip, (sum, v, key) => {
        return weights[key] ? sum += (parseInt(v) * weights[key]) : sum
    }, 0)

    return sum % 11 === controlNumber;
}

extend('nip', {
    message: 'Nieprawidłowy numer NIP',
    validate: nipValidator
});

extend('nipUnique', {
    message: 'Podany numer NIP jest już powiązany z innym użytkownikiem',
    validate: async nip => {
        nip = parseNip(nip)
        if (nip.length === 10) {
            const {data} = await UserService.checkNIPAvailability(nip);
            return data.available
        }

        return true;
    }
})

extend('validatePostalCode', {
    message: 'Wprowadzony kod pocztowy nie istnieje',
    validate: async postalCode => {
        const {data} = await UserService.checkPostalCode(postalCode);
        return data.isValid
    }
})

extend('phone', {
    message: 'Numer telefonu musi składać się z 9 cyfr',
    validate: value => {
        return value.length === 9;
    }
})

extend('postalCode', {
    message: 'Kod pocztowy musi być w formacie 00-000',
    validate: value => {
        return /^[0-9]{2}-[0-9]{3}$/.test(value);
    }
})


extend('parcelLocker', {
    message: 'Proszę wybrać paczkomat',
    validate: value => {
        return !!value.name;
    }
})

extend('password', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: 'Nowe hasła muszą być takie same'
});
localize('pl', pl);

export function getValidationState({dirty, validated, valid = null}) {
    return dirty || validated ? valid : null;
}