<template>
  <div class="row">
    <div class="col-lg-8 p-0">
      <valuation-details v-for="(order, index) in orders"
                         :priceTable="priceTable"
                         :inpostCourierEnabled="inpostCourierEnabled"
                         :decontaminationPeriods="decontaminationPeriods"
                         :order="orders[index]"
                         :index="index"
                         :key="order.id"
                         :show-action-buttons="orders.length > 1"
                         :value-of-model="valueOfModel"
                         @remove="handleRemove"
                         @update="updateOrder"
                         @calculatePrice="calculatePrice"/>

      <b-button block class="new-cabinet text-white pl-3 text-left mb-4 mb-lg-5" @click="addNewOrder"
                v-if="orders.length < maxNumberOfOffices ">
        <svg viewBox="0 0 16 16" class="bi bi-plus-circle new-cabinet-icon" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
          <path fill-rule="evenodd"
                d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
          <path fill-rule="evenodd"
                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        </svg>
        <span class="ml-3">Dodaj kolejny gabinet</span>
      </b-button>
    </div>
    <div class="col-lg-4 p-0 pl-lg-4 service-price-section">
      <div class="shadow sticky-top">
        <div class="header price-summary rounded-top row m-0 col-12 align-items-center">
          <span class="rounded-circle circle-number text-center text-white mr-3">2</span>
          Wycena
        </div>
        <div class="valuation-content col-12 rounded-bottom">

          <div class="research-header-container">
            <div class="research-header-price">{{this.priceTest}}</div>
            <div class="research-header-text">Badanie laboratoryjne</div>
          </div>

          <div class="valuation-section">
            <div class="spinner-border spinner" v-if="calculationsInProgress > 0" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else class="valuation-section-label">{{ sporals.toFormat() }}</div>
            <div class="valuation-section-value">- Testy biologiczne aquatest&reg;</div>
          </div>

          <div class="valuation-section">
            <div class="spinner-border spinner" v-if="calculationsInProgress > 0" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else class="valuation-section-label">{{ shipping.toFormat() }}</div>
            <div class="valuation-section-value">- Koszt dostawy w dwie strony</div>
          </div>

          <div class="valuation-section valuation-section-dark">
            <div class="spinner-border spinner" v-if="calculationsInProgress > 0" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else class="valuation-section-label">{{ price.toFormat() }}</div>
            <div class="valuation-section-value valuation-section-value-dark">- całkowity koszt usługi</div>
          </div>

          <div class="valuation-new-benefits">
            <span class="valuation-new-benefits-header">Dodatkowe korzyści</span>
            <ul class="col-12 ml-3">
              <li class="valuation-new-benefits-li" style="font-weight:bold;">Pierwsze badanie to tylko 1,23 zł!</li>
              <li class="valuation-new-benefits-li">Oszczędność czasu - nie musisz już więcej transportować testów</li>
              <li class="valuation-new-benefits-li">Przejrzyste i jasne zasady współpracy</li>
              <li class="valuation-new-benefits-li">Archiwum protokołów</li>
            </ul>
          </div>
          <div class="row col-12 m-0 mt-3">
            <button class="btn order-button text-white w-100" :disabled="isOrderButtonActive()"
                    @click="handleOrder">
              Zamawiam badanie
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isEmpty, pullAt, reduce, some} from 'lodash'
import Dinero from 'dinero.js';
import ValuationDetails from './ValuationDetails';
import {OrderService} from '../../api.service';
import {toPrice} from '../../helpers/price';
import {courierType, orderStructure} from '../../config';
import {logError} from '../../helpers/errorLogger';
import {v4 as uuidv4} from 'uuid';

export default {
  components: {
    ValuationDetails
  },
  props: {
    ordersData: Array,
    priceTable: Object,
    orderRoute: String,
    decontaminationPeriods: Array,
    inpostCourierEnabled: String,
    priceTest: String,
  },
  data() {
    return {
      orders: [],
      valueOfModel: this.getCookie('entity'),
      maxNumberOfOffices: this.priceTable['max_number_of_offices'] || 20,
      price: toPrice(0),
      shipping: toPrice(0),
      savings: toPrice(0),
      sporals: toPrice(0),
      testPrice: this.priceTest,
      calculationsInProgress: 0,
    }
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      try {
        const orders = JSON.parse(localStorage.getItem('orders'))
        this.orders = isEmpty(orders) ? [Object.assign({}, orderStructure)] : orders
      } catch (e) {
        logError(e)
        localStorage.removeItem('orders')
        this.$emit('error')
      }
    },
    saveOrdersToLocalStorage() {
      localStorage.setItem('orders', JSON.stringify(this.orders))
    },
    isOrderButtonActive() {
      return some(this.orders, order => {
        return order.A + order.S === 0
      })
    },
    handleRemove(index) {
      pullAt(this.orders, index);
      this.$nextTick(() => {
        this.orders = Object.assign([], this.orders);
        this.saveOrdersToLocalStorage()
        this.recalculate()
      })
    },
    getCookie(name) {
      let dc = document.cookie;
      let prefix = name +"=";
      let begin = dc.indexOf("; " + prefix);
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0)return null;
      } else {
        begin += 2;
      }
      let end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
      return unescape(dc.substring(begin + prefix.length, end));
    },
    addNewOrder() {
      const newOrder = {...orderStructure};
      newOrder.id = uuidv4();
      this.orders.push(newOrder);
      this.saveOrdersToLocalStorage()
    },
    updateOrder({key, value, index}) {
      if(key === 'is_medical_entity') {
        this.orders.forEach((order) => {
          order[key] = value
        })
      }
      else {
        this.orders[index][key] = value;
      }
      this.saveOrdersToLocalStorage()
    },
    shouldCalculatePrice() {
      return some(this.orders, order => {
        return order.A + order.S > 0
      })
    },
    getShipmentType(sporal) {
      const {courier, inpost_type} = sporal;
      if (courier === courierType.DPD) {
        return courierType.DPD
      }
      return inpost_type === courierType.INPOST_COURIER ? courierType.INPOST_COURIER : courierType.INPOST_LOCKER
    },
    calculatePrice(index, data) {
      if (!this.shouldCalculatePrice()) {
        this.price = toPrice(0)
        this.savings = toPrice(0)
        this.shipping = toPrice(0)
        this.sporals = toPrice(0)
      } else {
        this.getPrice(data, index)
      }
    },
    countNumberOfSporals(sporal) {
      const {number_of_sporals, A, S} = sporal
      return {
        A: A * number_of_sporals,
        S: S * number_of_sporals
      };
    },
    handleOrder() {
      this.saveOrdersToLocalStorage()
      window.location.href = this.orderRoute;
    },
    recalculate() {
      if (!this.shouldCalculatePrice()) {
        this.price = toPrice(0)
        this.savings = toPrice(0)
        this.shipping = toPrice(0)
        this.sporals = toPrice(0)
      } else {
        this.price = reduce(this.orders, (sum, v) => {
          return sum.add(toPrice(v.testSum).add(toPrice(v.shipping)))
        }, Dinero(toPrice(0)))

        this.savings = reduce(this.orders, (sum, v) => {
          return sum.add(toPrice(v.savings))
        }, Dinero(toPrice(0)))

        this.shipping = reduce(this.orders, (sum, v) => {
          return sum.add(toPrice(v.shipping))
        }, Dinero(toPrice(0)))

        this.sporals = reduce(this.orders, (sum, v) => {
          return sum.add(toPrice(v.sporals))
        }, Dinero(toPrice(0)))
      }
    },
    async getPrice(order, index) {
      this.calculationsInProgress++
      try {
        const {A, S} = this.countNumberOfSporals(order)
        const shipment = this.getShipmentType(order);
        const perAutoclave = order.number_of_sporals;
        const calcData = {A, S, shipment, perAutoclave}
        const {data: {savings, shipping, testSum, sporals}} = await OrderService.calculatePrice({...calcData})
        this.orders[index].savings = savings
        this.orders[index].shipping = shipping
        this.orders[index].testSum = testSum
        this.orders[index].sporals = sporals
      } catch (e) {
        logError(e)
        this.$emit('error')
      }

      this.calculationsInProgress--;
      this.recalculate();
    },
  },
  watch:{
    calculationsInProgress: function () {
      this.valueOfModel = this.getCookie('entity')
    }
  }
}
</script>