var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "d-flex flex-column mr-2" }, [
        _c(
          "label",
          {
            staticClass: "text-uppercase col-form-label mr-2 text",
            attrs: { for: `autoclaves-${_vm.type}-${_vm.index}` },
          },
          [
            _vm.type == "S"
              ? _c("span", [
                  _vm._v(
                    "\n                  Liczba sterylizatorów na suche gorące powietrze\n                "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n                  Liczba autoklawów parowych\n                "
                  ),
                ]),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "help-text" }, [
          _vm._v("Koszt 1 szt. " + _vm._s(_vm.price.toFormat())),
        ]),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control sporals-number-input flex-row",
        attrs: {
          type: "number",
          id: `autoclaves-${_vm.type}-${_vm.index}`,
          min: "0",
          max: "10",
          required: "",
        },
        domProps: { value: _vm.value },
        on: { input: _vm.update },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row col-12 m-0 p-0 mt-2" }, [
      _c("input", {
        staticClass: "custom-range",
        attrs: {
          type: "range",
          id: `autoclaves-slider-${_vm.type}-${_vm.index}`,
          min: "0",
          max: "10",
        },
        domProps: { value: _vm.value },
        on: { input: _vm.update },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }