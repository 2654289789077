<template>
    <div>
        <div class="form-row">
            <div class="d-flex flex-column mr-2">
                <label class="text-uppercase col-form-label mr-2 text" :for="`autoclaves-${type}-${index}`">
                    <span v-if="type == 'S'">
                      Liczba sterylizatorów na suche gorące powietrze
                    </span>
                    <span v-else>
                      Liczba autoklawów parowych
                    </span>
                </label>
                <span class="help-text">Koszt 1 szt. {{price.toFormat()}}</span>
            </div>
            <input class="form-control sporals-number-input flex-row"
                   type="number"
                   :id="`autoclaves-${type}-${index}`"
                   min="0"
                   max="10"
                   required
                   :value="value"
                   @input="update"/>
        </div>
        <div class="row col-12 m-0 p-0 mt-2">
            <input type="range"
                   class="custom-range"
                   :id="`autoclaves-slider-${type}-${index}`"
                   min="0"
                   max="10"
                   :value="value"
                   @input="update">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            index: Number,
            value: Number,
            type: String,
            price: Object
        },
        methods: {
            update(e) {
                const inputValue = parseInt(e.target.value || '0')
                e.target.value = inputValue >= 0 && inputValue <=10 ? inputValue : 0
                this.$emit('input', e.target.value)
            }
        }
    }
</script>
