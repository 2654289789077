var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shadow valuation-details-section" },
    [
      _c(
        "div",
        {
          staticClass:
            "header valuation-details rounded-top row m-0 col-12 align-items-center d-flex justify-content-between",
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c(
              "div",
              {
                staticClass:
                  "rounded-circle circle-number text-center text-white mr-3 align-self-center",
              },
              [_vm._v("1")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "header-text-lg" }, [
              _vm._v("Szczegóły wyceny dla "),
              _c("strong", [
                _vm._v(" gabinetu " + _vm._s(_vm.index + 1) + " "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "header-text" }, [
              _c("strong", [_vm._v(" Gabinet " + _vm._s(_vm.index + 1) + " ")]),
            ]),
          ]),
          _vm._v(" "),
          _vm.showActionButtons
            ? _c(
                "div",
                { staticClass: "office-actions" },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "text-decoration-none text-white",
                      on: { click: _vm.handleCollapse },
                    },
                    [
                      _vm.isCollapsed
                        ? _c("span", [_vm._v("Zapisz")])
                        : _c("span", [_vm._v("Edytuj")]),
                    ]
                  ),
                  _vm._v("\n      |\n      "),
                  _c(
                    "b-link",
                    {
                      staticClass: "text-decoration-none text-white",
                      on: { click: _vm.handleRemove },
                    },
                    [_c("span", [_vm._v("Usuń")])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          model: {
            value: _vm.isCollapsed,
            callback: function ($$v) {
              _vm.isCollapsed = $$v
            },
            expression: "isCollapsed",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content col-12 fwont-weight-bold rounded-bottom" },
            [
              _c("autoclave", {
                attrs: {
                  type: "B",
                  price: _vm.priceTable.sporal.A,
                  index: _vm.index,
                },
                model: {
                  value: _vm.A,
                  callback: function ($$v) {
                    _vm.A = _vm._n($$v)
                  },
                  expression: "A",
                },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("autoclave", {
                attrs: {
                  type: "S",
                  price: _vm.priceTable.sporal.S,
                  index: _vm.index,
                },
                model: {
                  value: _vm.S,
                  callback: function ($$v) {
                    _vm.S = _vm._n($$v)
                  },
                  expression: "S",
                },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row col-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio col-12 col-lg-6 pr-0",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.number_of_sporals,
                          expression: "number_of_sporals",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: `oneSporalPerAutoclave-${_vm.index}`,
                        value: "1",
                        name: `sporalsPerAutoclave-${_vm.index}`,
                      },
                      domProps: {
                        checked: _vm._q(_vm.number_of_sporals, _vm._n("1")),
                      },
                      on: {
                        change: function ($event) {
                          _vm.number_of_sporals = _vm._n("1")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-uppercase",
                        attrs: { for: `oneSporalPerAutoclave-${_vm.index}` },
                      },
                      [_vm._v("\n            1 test na autoklaw\n          ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.number_of_sporals,
                          expression: "number_of_sporals",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: `twoSporalsPerAutoclave-${_vm.index}`,
                        name: `sporalsPerAutoclave-${_vm.index}`,
                        value: "2",
                      },
                      domProps: {
                        checked: _vm._q(_vm.number_of_sporals, _vm._n("2")),
                      },
                      on: {
                        change: function ($event) {
                          _vm.number_of_sporals = _vm._n("2")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-uppercase",
                        attrs: { for: `twoSporalsPerAutoclave-${_vm.index}` },
                      },
                      [_vm._v("\n            2 testy na autoklaw\n          ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.number_of_sporals,
                          expression: "number_of_sporals",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: `threeSporalsPerAutoclave-${_vm.index}`,
                        name: `sporalsPerAutoclave-${_vm.index}`,
                        value: "3",
                      },
                      domProps: {
                        checked: _vm._q(_vm.number_of_sporals, _vm._n("3")),
                      },
                      on: {
                        change: function ($event) {
                          _vm.number_of_sporals = _vm._n("3")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-uppercase",
                        attrs: { for: `threeSporalsPerAutoclave-${_vm.index}` },
                      },
                      [_vm._v("\n            3 testy na autoklaw\n          ")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row col-12 align-items-center d-flex" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "pl-0 text-uppercase col-12 col-md-6",
                      attrs: { for: `sterilization-period-${_vm.order.id}` },
                    },
                    [
                      _vm._v("\n          Częstotliwość badania Sporal: "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            value:
                              "Wybierz co ile dni chcesz wykonywać sterylizację",
                            expression:
                              "'Wybierz co ile dni chcesz wykonywać sterylizację'",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "fas fa-question-circle",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-form-select", {
                    staticClass: "col-12 col-md-6",
                    attrs: {
                      id: `sterilization-period-${_vm.order.id}`,
                      options: _vm.decontaminationPeriods,
                    },
                    model: {
                      value: _vm.decontamination_period,
                      callback: function ($$v) {
                        _vm.decontamination_period = $$v
                      },
                      expression: "decontamination_period",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("CourierSelection", {
                attrs: {
                  courier: _vm.courier,
                  "shipment-price": _vm.priceTable.shipment,
                  inpostCourierEnabled: _vm.inpostCourierEnabled,
                  index: `${_vm.index}`,
                },
                on: { updateCourier: _vm.handleCourierUpdate },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row col-12 d-none" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio col-12 col-lg-6 pr-0",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.is_medical_entity,
                          expression: "is_medical_entity",
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: `isMedicalEntity-${_vm.index}`,
                        value: "false",
                        name: `isMedicalEntity-${_vm.index}`,
                      },
                      domProps: {
                        checked: _vm._q(_vm.is_medical_entity, "false"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.is_medical_entity = "false"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-uppercase",
                        attrs: { for: `isMedicalEntity-${_vm.index}` },
                      },
                      [
                        _vm._v(
                          "\n            Jestem podmiotem leczniczym\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.is_medical_entity,
                          expression: "is_medical_entity",
                        },
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: `isNotMedicalEntity-${_vm.index}`,
                        name: `isNotMedicalEntity-${_vm.index}`,
                        value: "true",
                      },
                      domProps: {
                        checked: _vm._q(_vm.is_medical_entity, "true"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.is_medical_entity = "true"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label text-uppercase",
                        attrs: { for: `isNotMedicalEntity-${_vm.index}` },
                      },
                      [
                        _vm._v(
                          "\n            Nie jestem podmiotem leczniczym\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }