<template>
  <div class="shadow valuation-details-section">
    <div class="header valuation-details rounded-top row m-0 col-12 align-items-center d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="rounded-circle circle-number text-center text-white mr-3 align-self-center">1</div>
        <div class="header-text-lg">Szczegóły wyceny dla <strong> gabinetu {{ index + 1 }} </strong></div>
        <div class="header-text"><strong> Gabinet {{ index + 1 }} </strong></div>
      </div>
      <div v-if="showActionButtons" class="office-actions">
        <b-link @click="handleCollapse" class="text-decoration-none text-white">
          <span v-if="isCollapsed">Zapisz</span>
          <span v-else>Edytuj</span>
        </b-link>
        |
        <b-link @click="handleRemove" class="text-decoration-none text-white">
          <span>Usuń</span>
        </b-link>
      </div>
    </div>
    <b-collapse v-model="isCollapsed">
      <div class="content col-12 fwont-weight-bold rounded-bottom ">
        <autoclave type="B" :price="priceTable.sporal.A" v-model.number="A" :index="index"/>
        <hr>
        <autoclave type="S" :price="priceTable.sporal.S" v-model.number="S" :index="index"/>
        <hr>
        <div class="row col-12">
          <div class="custom-control custom-radio col-12 col-lg-6 pr-0">
            <input type="radio"
                   :id="`oneSporalPerAutoclave-${index}`"
                   value="1"
                   :name="`sporalsPerAutoclave-${index}`"
                   class="custom-control-input"
                   v-model.number="number_of_sporals">
            <label class="custom-control-label text-uppercase"
                   :for="`oneSporalPerAutoclave-${index}`">
              1 test na autoklaw
            </label>
          </div>
          <div class="custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0">
            <input type="radio"
                   :id="`twoSporalsPerAutoclave-${index}`"
                   :name="`sporalsPerAutoclave-${index}`"
                   value="2"
                   class="custom-control-input"
                   v-model.number="number_of_sporals">
            <label class="custom-control-label text-uppercase"
                   :for="`twoSporalsPerAutoclave-${index}`">
              2 testy na autoklaw
            </label>
          </div>
          <div class="custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0">
            <input type="radio"
                   :id="`threeSporalsPerAutoclave-${index}`"
                   :name="`sporalsPerAutoclave-${index}`"
                   value="3"
                   class="custom-control-input"
                   v-model.number="number_of_sporals">
            <label class="custom-control-label text-uppercase"
                   :for="`threeSporalsPerAutoclave-${index}`">
              3 testy na autoklaw
            </label>
          </div>
        </div>
        <hr>
        <div class="row col-12 align-items-center d-flex ">
          <label class="pl-0 text-uppercase col-12 col-md-6" :for="`sterilization-period-${order.id}`">
            Częstotliwość badania Sporal: <i v-b-tooltip.hover="'Wybierz co ile dni chcesz wykonywać sterylizację'"
                                           class="fas fa-question-circle"></i></label>
          <b-form-select
              :id="`sterilization-period-${order.id}`"
              class="col-12 col-md-6"
              :options="decontaminationPeriods"
              v-model="decontamination_period"
          ></b-form-select>
        </div>
        <hr>
        <CourierSelection
            :courier="courier"
            :shipment-price="priceTable.shipment"
            :inpostCourierEnabled="inpostCourierEnabled"
            :index="`${index}`"
            @updateCourier="handleCourierUpdate"
        />
        <hr>
        <div class="row col-12 d-none">
          <div class="custom-control custom-radio col-12 col-lg-6 pr-0">
            <input type="radio"
                   :id="`isMedicalEntity-${index}`"
                   value="false"
                   :name="`isMedicalEntity-${index}`"
                   class="custom-control-input"
                   v-model="is_medical_entity">
            <label class="custom-control-label text-uppercase"
                   :for="`isMedicalEntity-${index}`">
              Jestem podmiotem leczniczym
            </label>
          </div>
          <div class="custom-control custom-radio col-12 col-lg-6 pr-0 mt-md-3 mt-lg-0">
            <input type="radio"
                   :id="`isNotMedicalEntity-${index}`"
                   :name="`isNotMedicalEntity-${index}`"
                   value="true"
                   class="custom-control-input"
                   v-model="is_medical_entity">
            <label class="custom-control-label text-uppercase"
                   :for="`isNotMedicalEntity-${index}`">
              Nie jestem podmiotem leczniczym
            </label>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import Autoclave from './Autoclave';
import {courierType} from '../../config';
import CourierSelection from './CourierSelection';
import axios from "axios";
export default {
  components: {
    CourierSelection,
    Autoclave
  },
  props: {
    order: Object,
    showActionButtons: Boolean,
    index: Number,
    priceTable: {
      type: Object,
      default: {}
    },
    valueOfModel: String,
    savingsTable: Object,
    decontaminationPeriods: Array,
    inpostCourierEnabled: String
  },
  data() {
    const {A, S, courier, decontamination_period, number_of_sporals, is_medical_entity} = this.order;
    return {
      isCollapsed: true,
      A,
      S,
      courier,
      decontamination_period,
      number_of_sporals,
      is_medical_entity,
      isLogged: false,
    }
  },
  watch: {
    number_of_sporals: function () {
      this.$emit('update', {
        key: 'number_of_sporals',
        value: this.number_of_sporals,
        index: this.index
      })
      this.calculatePrice();
    },
    valueOfModel: function () {
      this.is_medical_entity = this.valueOfModel;
    },
    decontamination_period: function () {
      this.$emit('update', {
        key: 'decontamination_period',
        value: this.decontamination_period,
        index: this.index
      })
      this.calculatePrice();
    },
    is_medical_entity: function () {
      this.$emit('update', {
        key: 'is_medical_entity',
        value: this.is_medical_entity,
        index: this.index
      })
      this.setCookie('entity', this.is_medical_entity, 1);
      this.calculatePrice();

    },
    A: function () {
      this.$emit('update', {key: 'A', value: this.A, index: this.index})
      this.calculatePrice();
    },
    S: function () {
      this.$emit('update', {key: 'S', value: this.S, index: this.index})
      this.calculatePrice();
    },
    order: function (oldOrder, newOrder) {
      const {A, S, courier, decontamination_period, number_of_sporals, is_medical_entity} = this.order;
      if (oldOrder.id !== newOrder.id) {
        this.A = A
        this.S = S
        this.courier = courier;
        this.decontamination_period = decontamination_period;
        this.number_of_sporals = number_of_sporals
        this.is_medical_entity = is_medical_entity
      }
    }
  },
  created() {
    if(this.getCookie('entity') === null){
      this.setCookie('entity', this.is_medical_entity, 1);
    }
    else {
      this.is_medical_entity = this.getCookie('entity')
      this.setCookie('entity', this.valueOfModel, 1);
    }
    this.calculatePrice();
  },
  beforeCreate() {
    axios.get('/checkiflogged').then(response => {
      if (response.data === 1){
        this.isLogged = true;
      }
    });
  },
  methods: {
    handleCourierUpdate(courier) {
      this.$emit('update', {key: 'courier', value: courier, index: this.index})
      this.calculatePrice();
    },
    getCookie(name) {
      let dc = document.cookie;
      let prefix = name +"=";
      let begin = dc.indexOf("; " + prefix);
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0)return null;
      } else {
        begin += 2;
      }
      let end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
      return unescape(dc.substring(begin + prefix.length, end));
    },
    handleRemove() {
      this.$emit('remove', this.index)
    },
    handleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    calculatePrice() {
      this.$emit('calculatePrice', this.index, this.$data)
    },
    setCookie(name,value,days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
  }
}
</script>