var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _vm.operationInProgress > 0 || _vm.error
      ? _c("div", { staticClass: "row calculator-placeholder" }, [
          _c("div", { staticClass: "card col-12 text-center py-5" }, [
            _c("div", { staticClass: "font-bold my-auto" }, [
              !_vm.error
                ? _c(
                    "div",
                    {
                      staticClass: "spinner-border",
                      staticStyle: { width: "3rem", height: "3rem" },
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Ładowanie..."),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "alert alert-danger m-0",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n          Nie udało się pobrać danych kalkulatora. Odśwież stronę aby spróbować ponownie.\n        "
                      ),
                    ]
                  ),
            ]),
          ]),
        ])
      : _c(
          "form",
          {
            staticClass: "col-12",
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("calculator-form", {
              attrs: {
                priceTable: _vm.priceTable,
                inpostCourierEnabled: _vm.inpostCourierEnabled,
                decontaminationPeriods: _vm.decontaminationPeriods,
                orderRoute: _vm.orderRoute,
                priceTest: _vm.priceTest,
              },
              on: { error: _vm.handleError },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }