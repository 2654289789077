import Dinero from 'dinero.js';
Dinero.globalLocale = 'pl-PL'
Dinero.defaultCurrency = 'PLN'

export function toPrice(amount) {
    return Dinero({amount}).setLocale('pl-PL')
}

export function formatPrice(price){
    return price.setLocale('pl-PL').toFormat();
}