$(document).ready(function () {
    const header = $(".sticky-top");

    $(window).on("scroll", function() {
        if ($(document).scrollTop() > 0) {
            header.addClass("shrink");
        } else {
            header.removeClass("shrink");
        }
    });

    $(".testimonials").slick({
        infinite: true,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    autoplay: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1199,
            }
        ]
    });
});
