var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-8 p-0" },
      [
        _vm._l(_vm.orders, function (order, index) {
          return _c("valuation-details", {
            key: order.id,
            attrs: {
              priceTable: _vm.priceTable,
              inpostCourierEnabled: _vm.inpostCourierEnabled,
              decontaminationPeriods: _vm.decontaminationPeriods,
              order: _vm.orders[index],
              index: index,
              "show-action-buttons": _vm.orders.length > 1,
              "value-of-model": _vm.valueOfModel,
            },
            on: {
              remove: _vm.handleRemove,
              update: _vm.updateOrder,
              calculatePrice: _vm.calculatePrice,
            },
          })
        }),
        _vm._v(" "),
        _vm.orders.length < _vm.maxNumberOfOffices
          ? _c(
              "b-button",
              {
                staticClass:
                  "new-cabinet text-white pl-3 text-left mb-4 mb-lg-5",
                attrs: { block: "" },
                on: { click: _vm.addNewOrder },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "bi bi-plus-circle new-cabinet-icon",
                    attrs: {
                      viewBox: "0 0 16 16",
                      fill: "currentColor",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v("Dodaj kolejny gabinet"),
                ]),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-lg-4 p-0 pl-lg-4 service-price-section" }, [
      _c("div", { staticClass: "shadow sticky-top" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "valuation-content col-12 rounded-bottom" }, [
          _c("div", { staticClass: "research-header-container" }, [
            _c("div", { staticClass: "research-header-price" }, [
              _vm._v(_vm._s(this.priceTest)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "research-header-text" }, [
              _vm._v("Badanie laboratoryjne"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "valuation-section" }, [
            _vm.calculationsInProgress > 0
              ? _c(
                  "div",
                  {
                    staticClass: "spinner-border spinner",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                )
              : _c("div", { staticClass: "valuation-section-label" }, [
                  _vm._v(_vm._s(_vm.sporals.toFormat())),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "valuation-section-value" }, [
              _vm._v("- Testy biologiczne aquatest®"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "valuation-section" }, [
            _vm.calculationsInProgress > 0
              ? _c(
                  "div",
                  {
                    staticClass: "spinner-border spinner",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                )
              : _c("div", { staticClass: "valuation-section-label" }, [
                  _vm._v(_vm._s(_vm.shipping.toFormat())),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "valuation-section-value" }, [
              _vm._v("- Koszt dostawy w dwie strony"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "valuation-section valuation-section-dark" },
            [
              _vm.calculationsInProgress > 0
                ? _c(
                    "div",
                    {
                      staticClass: "spinner-border spinner",
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading..."),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "valuation-section-label" }, [
                    _vm._v(_vm._s(_vm.price.toFormat())),
                  ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "valuation-section-value valuation-section-value-dark",
                },
                [_vm._v("- całkowity koszt usługi")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "row col-12 m-0 mt-3" }, [
            _c(
              "button",
              {
                staticClass: "btn order-button text-white w-100",
                attrs: { disabled: _vm.isOrderButtonActive() },
                on: { click: _vm.handleOrder },
              },
              [_vm._v("\n            Zamawiam badanie\n          ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "header price-summary rounded-top row m-0 col-12 align-items-center",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "rounded-circle circle-number text-center text-white mr-3",
          },
          [_vm._v("2")]
        ),
        _vm._v("\n        Wycena\n      "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "valuation-new-benefits" }, [
      _c("span", { staticClass: "valuation-new-benefits-header" }, [
        _vm._v("Dodatkowe korzyści"),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "col-12 ml-3" }, [
        _c(
          "li",
          {
            staticClass: "valuation-new-benefits-li",
            staticStyle: { "font-weight": "bold" },
          },
          [_vm._v("Pierwsze badanie to tylko 1,23 zł!")]
        ),
        _vm._v(" "),
        _c("li", { staticClass: "valuation-new-benefits-li" }, [
          _vm._v(
            "Oszczędność czasu - nie musisz już więcej transportować testów"
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "valuation-new-benefits-li" }, [
          _vm._v("Przejrzyste i jasne zasady współpracy"),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "valuation-new-benefits-li" }, [
          _vm._v("Archiwum protokołów"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }