import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './ValidatorSetup';
import VueRouter from 'vue-router'

require('./bootstrap');
require('slick-carousel');
require('./landing');
window.Vue = require('vue');

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueRouter)
Vue.component('calculator', require('./components/Calculator').default);

new Vue({}).$mount('#app');
