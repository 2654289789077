<template>
  <div class="container">
    <div class="row calculator-placeholder" v-if="operationInProgress > 0 || error">
      <div class="card col-12 text-center py-5">
        <div class="font-bold my-auto">
          <div v-if="!error" class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Ładowanie...</span>
          </div>
          <div class="alert alert-danger m-0" role="alert" v-else>
            Nie udało się pobrać danych kalkulatora. Odśwież stronę aby spróbować ponownie.
          </div>
        </div>
      </div>
    </div>
    <form class="col-12" v-else @submit.prevent>
      <calculator-form :priceTable="priceTable"
                       :inpostCourierEnabled="inpostCourierEnabled"
                       :decontaminationPeriods="decontaminationPeriods"
                       :orderRoute="orderRoute"
                       :priceTest="priceTest"
                       @error="handleError"/>
    </form>
  </div>
</template>

<script>
import {OrderService} from '../api.service';
import CalculatorForm from './calculator/CalculatorForm';
import {toPrice} from '../helpers/price';
import {logError} from '../helpers/errorLogger';
import {map} from 'lodash';

export default {
  components: {
    CalculatorForm
  },
  props: {
    orderRoute: String,
    inpostCourierEnabled: String
  },
  data() {
    return {
      operationInProgress: 0,
      error: false,
      priceTable: {},
      decontaminationPeriods: [],
      priceTest: null
    }
  },
  mounted() {
    this.getPriceTable()
    this.getDecontaminationPeriods()
  },
  methods: {
    handleError() {
      this.error = true;
    },
    async getPriceTable() {
      this.operationInProgress++;
      try {
        const {data} = await OrderService.priceTable();
        const {max_number_of_offices, shipment, sporal, test} = data;
        this.priceTest = toPrice(test).toFormat();
        this.priceTable = {
          max_number_of_offices,
          sporal: {
            A: toPrice(sporal.A),
            S: toPrice(sporal.S),
          },
          shipment: {
            DPD: toPrice(shipment.DPD),
            INPOST_COURIER: toPrice(shipment.INPOST_COURIER),
            INPOST_LOCKER: toPrice(shipment.INPOST_LOCKER),

          },
          test: toPrice(test)
        };

      } catch (e) {
        logError(e)
        this.error = true;
      }
      this.operationInProgress--;
    },
    async getDecontaminationPeriods() {
      this.operationInProgress++;
      try {
        const {data} = await OrderService.getDecontaminationPeriods();
        this.decontaminationPeriods = map(data, period => ({
          value: period, text: `co ${period} dni`
        }))
      } catch (e) {
        logError(e)
        this.error = true;
      }
      this.operationInProgress--;
    }
  }
}
</script>
